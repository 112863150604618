<template>
  <div class="d-flex-jk">
    <div class="d-flex-content">
      <div class="start-icon" :id="`${inputId}-start-icon`" v-if="startIcon">
        <div
        :id="`${inputId}-visibility-node`"
        class="icon-invisible"
        >
        <slot name="startIcon"> </slot>
        </div>
      </div>
      <div
        :class="['input-group']"
        :style="{ width: width }"
      > 
        <input type="text" v-if="inputType =='date'" data-date="" id="addProfileDatePickerAdd" @change= "getChangeFormat()" onfocus="(this.type='date')" data-date-format="DD/MM/YYYY" class="profileDate"  :placeholder="`${datePlaceholder}`" v-model="dateOfBirth" />
        <input
          v-else
          :type="inputType"
          :id="inputId"
          placeholder=" "
          :ref="reference"
          :value="value"
          @input="updateValue($event.target.value)"
          :disabled="disabled"
        />
        <label
          :class="[hasError ? 'error' : 'inactive', required ? 'required' : '']"
          style="text-overflow: ellipsis;"
          :for="inputId"
          >{{ label }}</label
        >
        <div class="endIcon">
          <slot name="endIcon"> </slot>
        </div>
      </div>
    </div>
    <div class="error-content">
      <p v-if="hasError"
        :class="[hasError ? 'error' : 'inactive']"
        class="font-regular-error"
        @click="this.$refs[this.ref].focus()"
      >
        {{ helperText }}
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {
      hasError: false,
      animationTimeout: null,
      datePlaceholder: "",
      dateOfBirth: ""

    };
  },

  props: {
    value: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    marginStart: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
      default: "",
    },
    helperText: {
      type: String,
      default: "",
    },
    inputType: {
      type: String,
      default: "text",
    },

    inputId: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: "100%",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    reference: {
      type: String,
      required: true,
    },
    startIcon: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    if(this.inputType == 'date' && this.value) {
      this.dateOfBirth = moment(this.value).format('DD/MM/YYYY');
    }
   
  },
  methods: {
    updateValue: function(value) {
      this.$emit("onChange", value);
    },
    getChangeFormat () {

      this.$emit("onChange",  moment(this.dateOfBirth).format('YYYY-MM-DD'));

     document.getElementById('addProfileDatePickerAdd').setAttribute(
        "data-date",
        moment(this.dateOfBirth, "YYYY-MM-DD")
        .format('DD/MM/YYYY')
    )

  },
  },
  watch: {
    value (v) {
      if(this.inputType == 'date' && this.value)

      this.dateOfBirth = moment(this.value).format('DD/MM/YYYY');
    },
    helperText: function(newValue, oldValue) {
      if (newValue === null || newValue === undefined) {
        this.hasError = false;
        return;
      }
      if (newValue.length > 0) {
        this.hasError = true;
      } else {
        this.hasError = false;
      }
      if (this.hasError) {
        try {
          this.$refs[this.inputId].focus();
        } catch (error) {
          //todo
        }
      }
    },
    marginStart: function(newValue, oldValue) {
      let iconNode = document.getElementById(`${this.inputId}-start-icon`);
      let visibilityNode = document.getElementById(`${this.inputId}-visibility-node`);
      let className = "start-icon-animate";
      let delayed = "delayed";
      if (this.animationTimeout !== null) {
        clearTimeout(this.animationTimeout);
      }
      if (newValue) {
        this.animationTimeout = setTimeout(() => {
          iconNode.classList.add(className);
          visibilityNode.classList.add(delayed);
        }, 100);
      } else {
        iconNode.classList.remove(className);
        visibilityNode.classList.remove(delayed);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "./JhakaasInput.scss";

.d-flex-jk {
  .d-flex-content{
    display: flex;
    justify-content: space-between;  
    gap: 0.5rem;
    width: 100%;
    margin: auto;
  }
}

#addProfileDatePickerAdd {
    position: relative;
    position: relative;
    width: 7rem;
    height: 2.5rem;
    color: white;
    margin: 0.4rem 0;
    padding: 0.7rem;
    color: white;
}

#addProfileDatePickerAdd:before {
    position: absolute;
    content: attr(data-date);
    display: inline-block;
}

 #addProfileDatePickerAdd::-webkit-datetime-edit, #addProfileDatePickerAdd::-webkit-inner-spin-button, #addProfileDatePickerAdd::-webkit-clear-button {
    display: none;
}

 #addProfileDatePickerAdd::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 2px;
    top: 4px;
    height: 7rem;
    width: 28px;
    color: black;
    opacity: 1;
}
.profileDate {
      // height: 2rem;
      // background: rgb(219, 195, 195);

      &::-webkit-calendar-picker-indicator {
        filter: invert(1) sepia(100%) saturate(10000%) hue-rotate(180deg);
      }
    }
  #userEmail {
    padding: 0.5rem;
  }

  #userEmail-start-icon {
    background: #101010 !important;
    border: 2px solid #1a1a1a !important;
    border-radius: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
  }
  .input-group {
    input {
      background: #101010 !important;
      border: 2px solid #1a1a1a !important;
      border-radius: 0.15rem;
    }
  }
</style>
